// .MuiBackdrop-root {
//     top: 0;
//     opacity: 0 !important;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     display: flex;
//     z-index: -1;
//     position: fixed;
//     align-items: center;
//     justify-content: center;
//     background-color: rgba(0, 0, 0, 0.5);
//     -webkit-tap-highlight-color: transparent;
// }
.img-icons{
    width: 18px; 
    height: 19.43px;
  
}
.content-lines{
    width: 91px;
    height: 24px;
    color:  #838d80;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  
}
.MuiListItemIcon-root {
    color: rgba(0, 0, 0, 0.54) !important;
     display: block !important; 
    min-width: 56px !important;
    flex-shrink: 0 !important;
    text-align: center !important;
}
.list_item{
    border-radius: 8px !important;
    width: 100% !important;
    // height: 100% !important;
    background: linear-gradient(90deg, #2d752f 0%, #9ec955 100%) !important;
    color: #fff !important;
}
.MuiDrawer-paper {
    top: 0 !important;
    flex: 1 0 auto !important;
    height: 100% !important;
    display: flex !important;
    outline: 0 !important;
    z-index: 1200 !important;
    position: fixed !important;
    overflow-y: auto !important;
    flex-direction: column !important;
    -webkit-overflow-scrolling: touch !important;
    background: #ffffff !important;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 7%) !important;
}
.MuiList-root {
    margin: 12px !important;
    margin-top: 0 !important;
    padding: 0 !important;
    position: relative !important;
    list-style: none !important;
}
.content-lines-active{
    width: 91px;
    // height: 24px;
    color: #ffffff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  
}
.log-out{
 width: 55px;
  height: 24px;
  color: #ca2d1c;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
//   position: fixed;
}
.div-line{
   position: fixed;
    bottom: 10px;
    left: 10px;
}
.MuiListItemIcon-root {
    color: rgba(0, 0, 0, 0.54) !important;
    display: block !important;
    min-width: 56px !important;
    flex-shrink: 0 !important;
    text-align: center !important;
}

.list_div{
    display: flex !important;
    align-items: center !important;
}
// .main-div{
//     position: fixed;
// }
.MuiListItem-gutters {
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
}
