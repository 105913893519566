.buyer-notifications-wrapper {
  // width: auto;
  width: 100%;
  // height: 100vh;

  .notifications-wrapper {
    // margin: 20px 40px;
    padding: 30px;
    width: calc(100% - 30px);
    margin-left: 30px;
    height: 70vh;
    border-radius: 12px;
    background: #ffffff;
    background-blend-mode: normal;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
    overflow-y: auto;
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-left: 0px;
      padding: 10px;
    }
    .notifications-list {
      height: auto;
      width: auto;
      display: flex;
      flex-direction: column;

      .notification {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 120px;

        img {
          width: 70px;
          height: 70px;
          border-radius: 8px;
          background: #d8d8d8;
          background-blend-mode: normal;
        }

        .typography {
          width: 85%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          font-family: Poppins;

          .detail {
            width: 100%;

            .status {
              font-weight: 600;
            }
          }

          .time {
            opacity: 0.4969773;
            color: #212121;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .notification-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: s;
    padding: 10px 40px;
    // width: 25%;
    align-items: center;
    @media screen and (max-width: 600px) {
      padding: 10px;
    }
    .notification-title {
      color: #212121;
      font-family: Poppins;
      font-size: 32px;
      font-weight: 600;
    }
    .notification-clear-all {
      margin-bottom: 0px;
      margin-left: 40px;
      color: #ff9938;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
}

.notif-img {
  margin-right: 10px;
}

.content-notifications-wrapper {
  width: 100%;
  // height: 100vh;

  .notifications-wrapper {
    // margin: 20px 40px;
    padding: 30px;
    width: calc(100% - 250px);
    margin-left: 250px;
    height: 80vh;
    border-radius: 12px;
    background: #ffffff;
    background-blend-mode: normal;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
    overflow-y: auto;
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-left: 0px;
      padding: 15px;
    }
    .notifications-list {
      height: auto;
      width: auto;
      display: flex;
      flex-direction: column;

      .notification {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 120px;

        img {
          width: 70px;
          height: 70px;
          border-radius: 8px;
          background: #d8d8d8;
          background-blend-mode: normal;
          @media screen and (max-width: 800px) {
            margin-left: 10px;
          }
        }

        .typography {
          width: 85%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          font-family: Poppins;

          .detail {
            width: 100%;

            .status {
              font-weight: 600;
            }
          }

          .time {
            opacity: 0.4969773;
            color: #212121;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .notification-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: s;
    padding: 10px 40px;
    // width: 25%;
    align-items: center;
    .notification-title {
      color: #212121;
      font-family: Poppins;
      font-size: 32px;
      font-weight: 600;
    }
    .notification-clear-all {
      margin-bottom: 0px;
      margin-left: 40px;
      color: #ff9938;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;
    }
  }
}

.h4-noti {
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: revert;
  text-align: center;
}

.footer-merchant-section {
  padding: 15px 0px;
  margin-left: 250px;
  @media screen and (max-width: 600px) {
    margin-left: 0px;
  }
}
.footer-buyer-section {
  padding: 20px 35px;
}

.buyer {
  background: #ffffff !important;
}

.merchant {
  background: rgb(248, 250, 251);
}
